.ShadesList {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 10;
}
