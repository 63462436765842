@import '../../variables';

.Header {
    padding: 2rem;
    box-shadow: 0 3px 6px rgba($dark, 0.15);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.Text {
    color: $grey;
}

.Undo,
.Redo,
.UnlockAll {
    margin: 0 1rem;
    fill: $primary;
    width: 2.5rem;
    height: 2.5rem;
    transition: fill 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        fill: $dark-primary;
    }
}

.UnlockAll {
    margin-right: 0;
}

.DisabledIcon {
    opacity: 0.6;
    cursor: default;

    &:hover {
        fill: $primary;
    }
}
