@import '../../variables';

.Svg {
    margin-top: 3rem;
    fill: $white;
    width: 4rem;
    height: 4rem;
    opacity: 0.6;
    transition: fill 0.25s ease-in-out;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }
}

.AnimatedColor {
    flex-grow: 1;
}

.Color {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.ColorValue {
    width: 100%;
    background: none;
    border: none;
    text-align: center;
    font-size: 3rem;
    color: $white;
    text-shadow: 0 0 4px $dark;
    letter-spacing: 1px;
    opacity: 0.4;
    cursor: pointer;
    margin-top: 4rem;

    &:focus {
        outline: none;
    }

    &:hover {
        opacity: 0.7;
    }
}

.RgbValue {
    font-size: 1.5rem;
    color: $white;
    text-shadow: 0 0 4px $dark;
    opacity: 0.8;
    margin: 4rem 0;
}

.ShadesButton {
    fill: $white;
    cursor: pointer;
    margin-top: 3rem;
    width: 3.5rem;
    height: 3.5rem;
    opacity: 0.6;

    &:hover {
        opacity: 0.8;
    }
}
